import React, { useEffect, useState } from 'react';
import useCartContant from '../Mainpage/useCartContant';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import Select from 'react-select';
import { Country, State, City } from 'country-state-city';
import useGetAddress from './useGetAddress';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';
import Loader from '../Loader/Loader';
import { Image } from 'react-bootstrap';
import paypal from '../../images/paypal.png'
import { Button, Modal, ListGroup } from "react-bootstrap";
import ReactDOM from "react-dom"


const CheckOut = () => {

    const navigate = useNavigate();

    const { cartproducts, loading, error, fetchCartProducts } = useCartContant();
    const { Address, load, err, fetchAddress } = useGetAddress();
    const [phone, setPhone] = useState('');
    const [selectedCountry, setSelectedCountry] = useState(null);
    const [selectedState, setSelectedState] = useState(null);
    const [selectedCity, setSelectedCity] = useState(null);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [update, setUpdate] = useState(false);
    const [selectedCoupon, setSelectedCoupon] = useState('');
    const [fastD, setFastD] = useState(false);
    const [payType, setPayType] = useState(null);
    const [show, setShow] = useState(false);
    const [showButton, setShowButton] = useState(false);
    const [orderId, setOrderId] = useState(null);
    const [OrderAmount, setOrderAmount] = useState(0);
    const [timeLeft, setTimeLeft] = useState(15 * 60); // 14 minutes in seconds
    const [isTimerActive, setIsTimerActive] = useState(false); // To track if the timer is active
    const [isTimerExpired, setIsTimerExpired] = useState(false); // To track if the timer has expired
    const [couponCode, setCouponCode] = useState('');
    const [formData, setFormData] = useState({
        user_id: localStorage.getItem('user_id'),
        name: '',
        address_line_1: '',
        address_line_2: '',
        city: '',
        state: '',
        country: '',
        pincode: '',
        contact_no: ''
    });

    const check = async () => {

        const token = localStorage.getItem('token');

        try {
            const response = await fetch('https://backend.amjgems.com/api/user-check', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: localStorage.getItem('user_id') }),
            });
            const data = await response.json();

        } catch (error) {
            navigate('/');
        }
    }
    useEffect(() => {
        check();
        fetchCartProducts();
        fetchAddress();

    }, []);

    useEffect(() => {
        if (Address) {
            setUpdate(true);
            setFormData({
                ...formData,
                name: Address.name || '',
                address_line_1: Address.address_line_1 || '',
                address_line_2: Address.address_line_2 || '',
                city: Address.city || '',
                state: Address.state || '',
                country: Address.country || '',
                pincode: Address.pincode || '',
                contact_no: Address.contact_no || ''
            });
            setPhone(Address.contact_no || '');

            const countryOption = Country.getAllCountries().find(country => country.name === Address.country);
            setSelectedCountry(countryOption ? { value: countryOption.isoCode, label: countryOption.name } : null);

            if (countryOption) {
                const statesList = State.getStatesOfCountry(countryOption.isoCode);
                setStates(statesList.map(state => ({
                    value: state.isoCode,
                    label: state.name
                })));

                const stateOption = statesList.find(state => state.name === Address.state);
                setSelectedState(stateOption ? { value: stateOption.isoCode, label: stateOption.name } : null);

                if (stateOption) {
                    const citiesList = City.getCitiesOfState(countryOption.isoCode, stateOption.isoCode);
                    setCities(citiesList.map(city => ({
                        value: city.name,
                        label: city.name
                    })));

                    const cityOption = citiesList.find(city => city.name === Address.city);
                    setSelectedCity(cityOption ? { value: cityOption.isoCode, label: cityOption.name } : null);
                }
            }
        }
        else {
            setUpdate(false);
            setFormData({
                ...formData,
                name: '',
                address_line_1: '',
                address_line_2: '',
                city: '',
                state: '',
                country: '',
                pincode: '',
                contact_no: ''
            });
            setPhone('');
            setSelectedCity(null);
            setSelectedState(null);
            setSelectedCountry(null);
            setStates([]);
            setCities([]);

        }
    }, [Address]);

    useEffect(() => {
        // Start a countdown timer for 14 minutes
        if (orderId != null) {

            const token = localStorage.getItem('token');

            const intervalId = setInterval(() => {
                setTimeLeft((prevTime) => {
                    if (prevTime <= 0) {
                        clearInterval(intervalId); // Stop the timer when it hits 0
                        setIsTimerExpired(true); // Mark the timer as expired
                        setIsTimerActive(false);
                        setShowButton(false);
                        try {
                            const response = fetch('https://backend.amjgems.com/api/delete-order', {
                                method: 'POST',
                                headers: {
                                    'Content-Type': 'application/json',
                                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                                },
                                body: JSON.stringify({ user_id: localStorage.getItem('user_id'), order_id: orderId }),
                            });
                            const data = response.json();

                        } catch (error) {
                            console.error('Error checking admin status:', error);
                        }
                        return 0; // Reset timeLeft to 0
                    }
                    return prevTime - 1; // Decrease the timer by 1 second
                });
            }, 1000);
        }
    }, [orderId]);

    const handleCountryChange = (selectedOption) => {
        setSelectedCountry(selectedOption);
        const statesList = State.getStatesOfCountry(selectedOption.value);
        setStates(statesList.map(state => ({
            value: state.isoCode,
            label: state.name
        })));
        setCities([]);
        setFormData(prevData => ({
            ...prevData,
            country: selectedOption.label
        }));
    };

    const handleStateChange = (selectedOption) => {
        setSelectedState(selectedOption);
        const citiesList = City.getCitiesOfState(selectedCountry.value, selectedOption.value);
        setCities(citiesList.map(city => ({
            value: city.name,
            label: city.name
        })));
        setFormData(prevData => ({
            ...prevData,
            state: selectedOption.label
        }));
    };

    const handleCityChange = (selectedOption) => {
        setSelectedCity(selectedOption);
        setFormData(prevData => ({
            ...prevData,
            city: selectedOption.label
        }));
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevData => ({
            ...prevData,
            [name]: value
        }));
    };

    const handlePhoneChange = (value) => {
        setPhone(value);
        setFormData(prevData => ({
            ...prevData,
            contact_no: value
        }));
    };

    const handleSubmit = async (e) => {

        e.preventDefault();
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://backend.amjgems.com/api/address/store';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify(formData)
            });

            if (response.ok) {
                const result = await response.json();
                fetchAddress();
                Swal.fire({
                    title: 'Address Added Successful!',
                })
                // Handle success (e.g., redirect or show a success message)
            } else {
                const error = await response.json();
                console.error('Error:', error);
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            console.error('Network error:', error);
            // Handle network error (e.g., show an error message)
        }
    };

    const handleDelete = async (e) => {
        e.preventDefault();
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
        // Example API endpoint
        const apiEndpoint = 'https://backend.amjgems.com/api/address/delete';

        try {
            const response = await fetch(apiEndpoint, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}` // Using Bearer token authentication
                },
                body: JSON.stringify({ user_id: user_id })
            });

            if (response.ok) {
                const result = await response.json();
                fetchAddress();
                setUpdate(false);
                Swal.fire({
                    title: 'Address Deleted Successful!',
                })
                // Handle success (e.g., redirect or show a success message)
            } else {
                const error = await response.json();
                // Handle error (e.g., show an error message)
            }
        } catch (error) {
            Swal.fire({
                title: 'Error Deleting Address !',
            })
        }
    }

    const handleFastShippingChange = (event) => {
        const isChecked = event.target.checked; // Get the checkbox status
        setFastD(isChecked); // Set fastD based on checkbox status
        const s = selectedCoupon ? selectedCoupon.id : ''
        fetchCartProducts(s, isChecked);
    }

    useEffect(() => {
        const s = selectedCoupon ? selectedCoupon.id : '';
        fetchCartProducts(s, fastD);

    }, [selectedCoupon])


    const handlePayType = (e) => {
        setPayType(e.target.id);
    }

    // // Function to load the Razorpay script dynamically
    // const loadRazorpayScript = () => {
    //     return new Promise((resolve, reject) => {
    //         const script = document.createElement('script');
    //         script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    //         script.async = true;
    //         script.onload = () => resolve(true);
    //         script.onerror = () => reject(false);
    //         document.body.appendChild(script);
    //     });
    // };

    // // Function to handle Razorpay payment
    // const handleRazorpayPayment = async (order) => {
    //     try {
    //         const token = localStorage.getItem('token');
    //         const user_id = localStorage.getItem('user_id') || '';

    //         // Load Razorpay SDK
    //         const scriptLoaded = await loadRazorpayScript();
    //         if (!scriptLoaded) {
    //             console.error('Failed to load Razorpay SDK');
    //             return;
    //         }

    //         // Fetch Razorpay order details from the backend
    //         const razorpayResponse = await axios.post(
    //             'https://backend.amjgems.com/api/create-razorpay-order',
    //             { user_id, order_id: order },
    //             {
    //                 headers: {
    //                     Authorization: `Bearer ${token}`,
    //                     'Content-Type': 'application/json',
    //                 },
    //             }
    //         );

    //         const { key, order_id, amount, currency } = razorpayResponse.data;

    //         // Razorpay payment options
    //         const options = {
    //             key,
    //             amount: amount * 100, // Convert to smallest currency unit (e.g., paise for INR)
    //             currency,
    //             name: 'Amazing Gems And Jewellers',
    //             description: 'Order Payment',
    //             order_id,
    //             handler: async function (response) {
    //                 try {
    //                     // Send payment details for verification
    //                     const payment = await axios.post(
    //                         'https://backend.amjgems.com/api/verify-razorpay-payment',
    //                         {
    //                             razorpay_order_id: response.razorpay_order_id,
    //                             razorpay_payment_id: response.razorpay_payment_id,
    //                             razorpay_signature: response.razorpay_signature,
    //                         }, {
    //                         headers: {
    //                             Authorization: `Bearer ${token}`,
    //                             'Content-Type': 'application/json',
    //                         },
    //                     }
    //                     );
    //                     console.log('Payment verified successfully');
    //                     console.log(payment);
    //                     console.log(payment.data);
    //                 } catch (verificationError) {
    //                     console.error('Payment verification failed:', verificationError);
    //                 }
    //             },
    //             prefill: {
    //                 name: formData.name,
    //                 email: '',
    //                 contact: formData.contact_no,
    //             },
    //             theme: {
    //                 color: '#3399cc',
    //             },
    //         };

    //         // Initialize and open Razorpay checkout
    //         const rzp = new window.Razorpay(options);
    //         rzp.on('payment.failed', function (response) {
    //             console.error('Payment failed:', response.error);
    //         });
    //         rzp.open();
    //     } catch (error) {
    //         console.error('Error processing Razorpay payment:', error);
    //     }
    // };
    // Function to format time in MM:SS format

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
    };

    const PayPalButton = window.paypal.Buttons.driver("react", {
        React,
        ReactDOM
    });
    const createOrder = (data, actions) => {
        // Order is created on the server and the order id is returned
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: OrderAmount,
                        currency_code: "USD",
                    },
                    reference_id: orderId
                },
            ],
        });
    };
    const onApprove = (data, actions) => {
        // Order is captured on the server and the response is returned to the browser
        return actions.order.capture().then(async function (details) {

            const token = localStorage.getItem('token');

            try {
                const response = await fetch('https://backend.amjgems.com/api/handle-paypal-payment', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}` // Using Bearer token authentication
                    },
                    body: JSON.stringify({ user_id: localStorage.getItem('user_id'), order_id: orderId, payment_id: details.id }),
                });
                const data = await response.json();
                if (data.success) {
                    Swal.fire({
                        title: data.message,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Redirect to the desired URL after the alert is closed
                            navigate('/check/profile/your-orders')
                        }
                    });
                }
                else {
                    Swal.fire({
                        title: `Some issue has been occured. Your Order Id : ${orderId} and Transaction Id : ${details.id}.`,
                    }).then((result) => {
                        if (result.isConfirmed) {
                            // Redirect to the desired URL after the alert is closed
                            navigate('/check/profile/your-orders')
                        }
                    });
                }

            } catch (error) {
                console.error('Error checking admin status:', error);
            }

        })
    };

    const submitPay = async (e) => {
        if (payType !== null) {
            if (Address) {

                e.preventDefault();
                const token = localStorage.getItem('token');
                const user_id = localStorage.getItem('user_id') ? localStorage.getItem('user_id') : '';
                let shipping = null;
                if (fastD) {
                    shipping = 'fast_shipping';

                }
                else {
                    if (cartproducts?.free_shipping) {
                        shipping = 'free_shipping';
                    }
                    else {
                        shipping = 'standard_shipping';
                    }

                }
                // Example API endpoint
                const apiEndpoint = 'https://backend.amjgems.com/api/place-order';

                try {
                    const response = await fetch(apiEndpoint, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': `Bearer ${token}` // Using Bearer token authentication
                        },
                        body: JSON.stringify({ user_id: user_id, payment_method: payType, coupon_id: cartproducts.coupon_applied !== "N/A" ? cartproducts.coupon_applied.id : '', shipping_type: shipping, fast_delivery: fastD })
                    });

                    if (response.ok) {
                        const result = await response.json();
                        if (result.payment_method == 'paypal') {

                            setOrderAmount(result.total_order_value);
                            setShowButton(true);
                            setTimeLeft(15 * 60);
                            setIsTimerActive(true); // Start the timer
                            setIsTimerExpired(false); // Ensure timer expired flag is reset
                            setOrderId(result.order_id);
                            // on changing orderId there is an useEffect which setInterval this is because order id is updating asyncronously;
                        }
                    }
                } catch (error) {
                    Swal.fire({
                        title: 'Some error Placing order !',
                    })
                }
            }
            else {
                Swal.fire({
                    title: 'Add Address !',
                })
            }
        }
        else {
            Swal.fire({
                title: 'Select Payment Option !',
            })
        }
    }

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const leftColClassName = update ? 'none' : 'left-col';
    const leftColClass = update ? 'left-col' : 'none';

    if (load) {
        return <Loader />;
    }
    if (err) {
        return <div className="error">Error: {err.message}</div>;
    }


    return (
        <div className="container-checkout" style={{ padding: '16px' }}>
            {/* Shipping Information */}
            <div className={leftColClassName} style={{ flex: '1.7' }}>
                <section className="checkout-shipping-info">
                    <h2 className="section-title">Shipping Information</h2>
                    <form className="shipping-form" onSubmit={handleSubmit}>
                        <label htmlFor="name" className="form-label">Full Name:</label>
                        <input
                            type="text"
                            id="name"
                            name="name"
                            className="form-input"
                            value={formData.name}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="address_line_1" className="form-label">Address Line 1 :</label>
                        <input
                            type="text"
                            id="address_line_1"
                            name="address_line_1"
                            className="form-input"
                            value={formData.address_line_1}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="address_line_2" className="form-label">Address Line 2 :</label>
                        <input
                            type="text"
                            id="address_line_2"
                            name="address_line_2"
                            className="form-input"
                            value={formData.address_line_2}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="country" className="form-label">Country:</label>
                        <Select
                            id="country"
                            name="country"
                            options={Country.getAllCountries().map(country => ({
                                value: country.isoCode,
                                label: country.name
                            }))}
                            className="form-select"
                            onChange={handleCountryChange}
                            value={selectedCountry}
                        />

                        <label htmlFor="state" className="form-label">State:</label>
                        <Select
                            id="state"
                            name="state"
                            options={states}
                            className="form-select"
                            onChange={handleStateChange}
                            value={selectedState}
                        />

                        <label htmlFor="city" className="form-label">City:</label>
                        <Select
                            id="city"
                            name="city"
                            options={cities}
                            className="form-select"
                            onChange={handleCityChange}
                            value={selectedCity}
                        />


                        <label htmlFor="pincode" className="form-label">Pincode/ZIP Code:</label>
                        <input
                            type="text"
                            id="pincode"
                            name="pincode"
                            className="form-input"
                            value={formData.pincode}
                            onChange={handleInputChange}
                            required
                        />

                        <label htmlFor="contact_no" className="form-label">Contact Number :</label>
                        <PhoneInput
                            placeholder="Enter phone number"
                            value={phone}
                            className="form-input"
                            onChange={handlePhoneChange}
                        />
                        <button type="submit" className="add_address_button">Add Address</button>
                    </form>
                </section>
            </div>
            <div className={leftColClass} style={{ flex: '1.7' }}>
                <section className="checkout-shipping-info">
                    <h2 className="section-title">Shipping Information</h2>
                    <div className="shipping-info">
                        <div className="info-item">
                            <strong>Full Name:</strong> {formData.name}
                        </div>
                        <div className="info-item">
                            <strong>Address_line_1:</strong> {formData.address_line_1}
                        </div>
                        <div className="info-item">
                            <strong>Address_line_2:</strong> {formData.address_line_2}
                        </div>
                        <div className="info-item">
                            <strong>City:</strong> {formData.city}
                        </div>
                        <div className="info-item">
                            <strong>State:</strong> {formData.state}
                        </div>
                        <div className="info-item">
                            <strong>Country:</strong> {formData.country}
                        </div>
                        <div className="info-item">
                            <strong>Pincode/ZIP Code:</strong> {formData.pincode}
                        </div>
                        <div className="info-item">
                            <strong>Contact Number:</strong> {phone}
                        </div>
                        <button
                            type="button"
                            onClick={() => setUpdate(false)}
                            className="add_address_button">
                            Update Address
                        </button>
                        <button
                            type="button"
                            onClick={handleDelete}
                            className="add_address_button">
                            Delete Address
                        </button>
                    </div>
                </section>
            </div>

            <div className="right-col" style={{ flex: '1.2' }}>
                {/* Order Summary */}
                <section className="checkout-order-summary">
                    <h2 className="section-title">Order Summary</h2>
                    {cartproducts.cart_items.map((cartproduct, index) => {
                        const discountedPrice = (cartproduct.variant.price * (1 - cartproduct.product.product_discount / 100)).toFixed(2);
                        return (
                            <div key={index}>
                                <ul className="order-list ">
                                    <li className="price_after_discount" >
                                        {cartproduct.product.product_name}
                                    </li>
                                    <li>
                                        ${(cartproducts.flat_discount ? discountedPrice : cartproduct.variant.price)} x {cartproduct.product_cart_quantity} =
                                        <span style={{ fontWeight: 'bold' }}> ${cartproduct.product_total_price}</span>

                                        {cartproducts?.flat_discount &&
                                            <div id="discountcc" style={{ margin: '0px' }}>
                                                [Price including discount <span>{cartproduct.product.product_discount}%</span>]
                                            </div>
                                        }
                                        {cartproduct?.adjustment_message &&
                                            <div id="quantity_adjusted">{cartproduct.adjustment_message}</div>
                                        }
                                    </li>
                                </ul>
                            </div>
                        );
                    })}

                    <div id='border'></div>
                    <div class="total"> Total : $ {cartproducts.cart_sum}</div>
                    {cartproducts?.coupon_applied !== "N/A" && cartproducts?.coupon_applied?.discount_coupon && (
                        <div className="cart-free-shipping">Coupon applied: {cartproducts.coupon_applied.discount_coupon} - {cartproducts.coupon_applied.discount_percent}%</div>
                    )}
                    {cartproducts?.coupon_applied?.discount_coupon &&
                        <>
                            <div id='border'></div>
                            <div className="price_after_discount"> Price after Coupon Discount : $ {cartproducts.discounted_price}</div>
                        </>
                    }
                    {cartproducts?.tax_percent !== undefined && <div class="tax"> Tax : % {cartproducts.tax_percent}</div>}
                    {fastD ?
                        <div class="cart-standard-shipping"> Fast shipping applied : $ {cartproducts?.fast_shipping_charges}</div> :
                        <>
                            {cartproducts?.free_shipping ?
                                <div class="cart-free-shipping"> Free delivery applied</div> :
                                <div class="cart-standard-shipping"> Standard shipping price : $ {cartproducts?.standard_shipping_price}</div>

                            }
                        </>
                    }
                    <div id='border'></div>
                    <div class="total" style={{ marginBottom: '1rem' }}> Sub Total : $ {cartproducts.subtotal_after_tax}</div>
                    <div id='extra'>
                        <div id="couponMenuContainer">
                            <Button id='couponMenu' onClick={handleShow}>
                                Select Coupons
                            </Button>
                        </div>
                        <Modal show={show} onHide={handleClose} centered>
                            <Modal.Header closeButton>
                                Coupons
                            </Modal.Header>
                            <Modal.Body>
                                <div className="mt-3 textCoupon">
                                    <input type="text" id="newCouponInput" value={couponCode} placeholder="Enter coupon code" onChange={(e) => { setCouponCode(e.target.value) }} />
                                    <Button variant="success"
                                        onClick={() => {
                                            fetchCartProducts(selectedCoupon ? selectedCoupon.id : '', fastD, couponCode)
                                            setShow(false);
                                            setCouponCode('');
                                        }} >
                                        Apply
                                    </Button>
                                </div>
                                <ListGroup>
                                    {cartproducts?.coupons?.length === 0 && <div>No coupons available</div>}
                                    {cartproducts?.coupons?.map((coupon) => (
                                        <ListGroup.Item key={coupon.id} className="d-flex justify-content-between align-items-center">
                                            <div>
                                                <h6><strong>{coupon.discount_coupon} - {coupon.discount_percent} %</strong></h6>
                                                <p id='couponDesc' className="mb-2 text-muted">{coupon.discount_description}</p>
                                            </div>
                                            {cartproducts?.total_cart_items >= coupon.quantity && parseInt(cartproducts.cart_sum) >= parseInt(coupon.price) ?
                                                <Button id={coupon.id}
                                                    onClick={(e) => {
                                                        const selectedId = parseInt(e.target.id);
                                                        const coupon = cartproducts?.coupons.find(v => v.id === selectedId);
                                                        setSelectedCoupon(coupon);
                                                        setShow(false);
                                                    }}
                                                    variant="success" >
                                                    Apply
                                                </Button>
                                                :
                                                <Button id={coupon.id}
                                                    onClick={(e) => {
                                                        navigate('/allproducts')
                                                    }}
                                                    variant="danger" >
                                                    Add more
                                                </Button>

                                            }
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>
                            </Modal.Body>
                            <Modal.Footer>

                            </Modal.Footer>
                        </Modal>
                        <label className="cart-fast-shipping">
                            <input
                                type="checkbox"
                                onChange={handleFastShippingChange} // Define this function to handle changes
                            />
                            Apply Fast Shipping: $ {cartproducts?.fast_shipping_charges}
                        </label>
                    </div>


                </section>

                {/* Payment Options */}
                <section className="checkout-payment-options">
                    {/* <label className="cart-fast-shipping">
                        <input
                            type="radio"
                            name="paymentOption" // Same name groups the radio buttons
                            id='razorpay'
                            onChange={handlePayType} // Define this function to handle changes
                        />
                        Payment via
                        <Image src={razorpay} alt="Razorpay" width="70" className="payment-logo" />
                    </label> */}

                    <label className="cart-fast-shipping">
                        <input
                            type="radio"
                            name="paymentOption" // Same name groups the radio buttons
                            id='paypal'
                            onChange={handlePayType} // Define this function to handle changes
                        />
                        Payment via
                        <Image src={paypal} alt="PayPal" width="70" className="payment-logo" />

                    </label>
                    {isTimerActive &&
                        <div id="timeLeft">
                            <p>Time Left: {formatTime(timeLeft)}</p>
                        </div>
                    }

                    {showButton ?
                        <PayPalButton
                            createOrder={
                                (data, actions) => createOrder(data, actions)
                            }
                            onApprove={
                                (data, actions) => onApprove(data, actions)
                            }
                        /> :
                        (cartproducts.total_cart_value != 0 && <button type="button" id="proceed-pay" onClick={submitPay} className="proceed-pay-button">Proceed to Pay</button>)
                    }



                </section>
            </div>
        </div>
    );
};

export default CheckOut;
