import React from 'react'
import { useState } from 'react';
import { Modal } from 'react-bootstrap';
import Swal from 'sweetalert2';

const ForgetPassModel = ({ show, onClose }) => {
    const [email, setemail] = useState('');
    const handleSubmitForgetPass = async (e) =>{
        e.preventDefault();
        try {
            const response = await fetch('https://backend.amjgems.com/api/forgot-password', { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({email}),
            });
            const data = await response.json();
            onClose();
            Swal.fire({
                title: 'Forgot password link sent to your E-mail!',
            })

        } catch (error) {
            console.error('Error adding category:', error);
        }

    }
    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainer">
                        <div id='fpdiv'>
                            <h1>Forget Password</h1>
                            <form id='fpform' onSubmit={handleSubmitForgetPass}>
                                <label id='fplable'>Enter Email</label>
                                <input
                                    type="email"
                                    placeholder='Enter E-mail'
                                    value={email}
                                    onChange={(e) => {
                                        setemail(e.target.value)
                                    }}
                                />
                                <div id='butdiv'>
                                    <button className='loginsignupbutton' type='submit'>Send Email</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ForgetPassModel