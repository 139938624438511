// src/components/SignupModal.js
import React, { useState } from 'react';
import { Modal, Carousel } from 'react-bootstrap';
import useCarousel from '../Admin/useCarousel';
import loginimage from '../../images/amazing gems and jewellery png with name.png';
import Swal from 'sweetalert2';
import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { Eye, EyeSlash } from 'react-bootstrap-icons'; // Import the icons

const SignupModal = ({ show, onClose }) => {
    const [index, setIndex] = useState(0);
    const [showPassword, setShowPassword] = useState(false);
    const { carousels } = useCarousel();

    const handleSelect = (selectedIndex) => {
        setIndex(selectedIndex);
    };

    // State variables for form fields
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [contactNo, setContactNo] = useState('');
    const [confirmpassword, setconfirmPassword] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState(null);

    const handleSubmit = async (event) => {
        event.preventDefault();

        try {
            const response = await fetch('https://backend.amjgems.com/api/user/signup', { // Replace with your API endpoint
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    name,
                    email,
                    password,
                    confirm_password: confirmpassword,
                    contact_no: contactNo
                }),
            });

            const data = await response.json();

            if (!data.success) {
                // Handle errors (e.g., validation errors) from the response
                setError(data);
                Swal.fire({
                    title: 'Failed to sign up: ' + (data.message || 'Unknown error'),
                })
                return;
            }
            else if (data.password === false) {
                // Handle errors (e.g., validation errors) from the response
                setError(data);
                Swal.fire({
                    title: 'Failed to sign up: ' + (data.message || 'Unknown error'),
                })
                return;
            }

            setError(null);
            onClose();
            Swal.fire({
                title: 'Sign Up Successful! Login to continue.',
            })

        } catch (error) {
            setError('An error occurred while signing up.');
        }
    };

    const handleVerifyEmail = async () => {
        if (email && name && contactNo && password && confirmpassword) {
            try {
                const response = await fetch('https://backend.amjgems.com/api/send-otp', { // Replace with your API endpoint
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                    }),
                });

                const data = await response.json();

                if (!response.ok) {
                    // Handle errors (e.g., validation errors) from the response
                    setError(data);
                    Swal.fire({
                        title: 'Failed to sign up: ' + (data.message || 'Unknown error'),
                    })
                    return;
                }

                setError(null);
                // Show the OTP form after verifying the email
                const verifyEmailButton = document.querySelector('.verifyEmail');
                const otpForm = document.querySelector('#fromVerifyEmail');
                const failed = document.querySelector('.resendOtp');

                if (verifyEmailButton) {
                    verifyEmailButton.style.display = 'none'; // Hide the verify button
                }

                if (otpForm) {
                    otpForm.style.display = 'block'; // Show the OTP form
                }
                if (failed) {
                    failed.style.display = 'none'; // Show the OTP form
                }


            } catch (error) {
                setError('An error occurred while signing up.');
            }
        }
        else {
            Swal.fire({
                title: 'Fill All Fields: ',
            })
        }
    }
    const handleVerifyOtpSubmit = async (e) => {
        e.preventDefault();
        if (otp) {
            try {
                const response = await fetch('https://backend.amjgems.com/api/verify-otp', { // Replace with your API endpoint
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({
                        email,
                        otp
                    }),
                });

                const data = await response.json();

                if (!response.ok) {
                    // Handle errors (e.g., validation errors) from the response
                    setError(data);
                    Swal.fire({
                        title: 'Failed to Verify !',
                    })
                    const failed = document.querySelector('.resendOtp');
                    const otpForm = document.querySelector('#fromVerifyEmail');

                    if (otpForm) {
                        otpForm.style.display = 'none'; // Show the OTP form
                    }

                    if (failed) {
                        failed.style.display = 'block'; // Show the OTP form
                    }
                    setOtp('');
                    return;
                }
                setError(null);
                // Show the OTP form after verifying the email
                const otpForm = document.querySelector('#fromVerifyEmail');

                const ss = document.querySelector('.SignupSubmitButton');
                const vv = document.querySelector('#otpVerifiedMsg');

                if (otpForm) {
                    otpForm.style.display = 'none'; // Show the OTP form
                }
                if (ss) {
                    ss.style.display = ''; // Show the OTP form
                }
                if (vv) {
                    vv.style.display = 'block'; // Show the OTP form
                }


            } catch (error) {
                setError('An error occurred while signing up.');
            }


        }
        else {
            Swal.fire({
                title: 'Fill OTP',
            })
        }

    }

    return (
        <>
            <Modal show={show} onHide={onClose}>
                <Modal.Header closeButton>
                    {/* <Modal.Title>Sign Up</Modal.Title> */}
                </Modal.Header>
                <Modal.Body>
                    <div className="popupcontainerls">
                        <img id='loginsignupimage' src={loginimage} alt="Login" />
                        <div className="popupcontainer">
                            <h1>Sign Up</h1>
                            <form onSubmit={handleSubmit}>
                                <input
                                    className='admininput'
                                    type="text"
                                    value={name}
                                    placeholder='Name'
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                />
                                <input
                                    className='admininput'
                                    type="email"
                                    value={email}
                                    placeholder='Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                />
                                <input
                                    className='admininput'
                                    type={showPassword ? "text" : "password"}
                                    value={password}
                                    placeholder='Password'
                                    onChange={(e) => setPassword(e.target.value)}
                                    required
                                />
                                <button
                                    type="button"
                                    onClick={() => setShowPassword(!showPassword)}
                                    className="show-password-btn-signup"
                                    aria-label={showPassword ? "Hide password" : "Show password"}
                                >
                                    {showPassword ? (
                                        <EyeSlash size={20} className="password-icon" />
                                    ) : (
                                        <Eye size={20} className="password-icon" />
                                    )}
                                </button>

                                <input
                                    className='admininput'
                                    type="password"
                                    value={confirmpassword}
                                    placeholder='retype Password'
                                    onChange={(e) => setconfirmPassword(e.target.value)}
                                    required
                                />
                                <PhoneInput
                                    placeholder="Enter phone number"
                                    value={contactNo}
                                    onChange={setContactNo} // Directly use the value returned
                                    className="form-input"
                                    required
                                />
                                <p className="discount" id='otpVerifiedMsg' style={{ display: 'none', textAlign: 'center' }}>OTP Verified!</p>
                                <div id='butdiv' className='SignupSubmitButton' style={{ display: 'none' }}>
                                    <button className="product-button" type='submit'>Submit</button>
                                </div>
                            </form>
                            <div id='butdiv' className='resendOtp' style={{ display: 'none' }}>
                                <button className="product-button" onClick={() => { handleVerifyEmail() }}>Re-send OTP</button>
                            </div>
                            <div id='butdiv' className='verifyEmail'>
                                <button className="product-button" onClick={() => { handleVerifyEmail() }}>verify Email</button>
                            </div>
                            <form id='fromVerifyEmail' style={{ display: 'none' }} onSubmit={handleVerifyOtpSubmit}>
                                <input
                                    style={{ border: '2.2px solid black' }}
                                    className='admininput'
                                    type="text"
                                    value={otp}
                                    placeholder='Enter OTP'
                                    onChange={(e) => setOtp(e.target.value)}
                                    required
                                />
                                <button className="product-button">Verify</button>
                            </form>
                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default SignupModal;
