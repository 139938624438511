import React, { useState, useEffect } from 'react';
import './Productshow.scss';
import useProductshow from './useProductshow';
import { Link, useParams } from 'react-router-dom';
import AddReviewModal from './AddReviewModal';
import LoginModal from '../Mainpage/LoginModal';
import Cookies from 'universal-cookie';
import CartModal from '../Mainpage/CartModal';
import { Carousel } from 'react-bootstrap'; // Import Carousel from react-bootstrap
import Loader from '../Loader/Loader';
import Swal from 'sweetalert2';
import DOMPurify from 'dompurify';
import { HeartFill, Share, Whatsapp, Twitter, Instagram } from 'react-bootstrap-icons';
import { Helmet } from 'react-helmet-async';
const cookies = new Cookies();

const Productshow = () => {
    // State management
    const [loginShow, setLoginShow] = useState(false);
    const [quantity, setQuantity] = useState(1);
    const [reviewShow, setreviewShow] = useState(false);
    const [reviews, setReviews] = useState([]);
    const [showCart, setShowCart] = useState(false);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [showPrice, setShowPrice] = useState(null);
    // Fetch product details
    const { product_code } = useParams();
    const { singleproduct, loading, error, fetchSingleProduct } = useProductshow(product_code);
    const [selectedVariant, setSelectedVariant] = useState(null);
    const [showOptions, setShowOptions] = useState(false);


    // Fetch reviews
    const fetchreviews = async () => {
        try {
            const response = await fetch(`https://backend.amjgems.com/api/reviews/${product_code}?limit=5`, { method: 'GET' });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setReviews(data);
        } catch (error) {
            console.log(error.message);
        }
    };

    // Side effects
    useEffect(() => {
        window.scrollTo(0, 0);
        if (product_code) {
            fetchSingleProduct()
        }
    }, [product_code]);

    useEffect(() => {
        if (singleproduct) {
            setShowPrice(singleproduct.lowest_variant?.price || 0); // Use a default value or keep it 0 if price is not available
            setSelectedVariant(singleproduct.lowest_variant || null);
        }
    }, [singleproduct]);


    useEffect(() => {
        if (!reviewShow) {
            fetchreviews();
        }
    }, [reviewShow]);

    // Event handlers
    const handleShowLogin = () => setLoginShow(true);
    const handleShowReview = () => setreviewShow(true);
    const handleClose = () => {
        setLoginShow(false);
        setShowCart(false);
    };
    const handleCloseAddReview = () => {
        setreviewShow(false);
        fetchreviews();
    }
    const handleShowCart = () => setShowCart(true);


    const quantityHandler = () => {
        if (singleproduct?.product?.single_product > 0) {
            if (quantity < singleproduct?.lowest_variant?.quantity) {
                setQuantity(quantity + 1);
            }
        }
        else {
            if (quantity < selectedVariant.quantity) {
                setQuantity(quantity + 1);
            }
        }
    };

    const quantityReducer = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1);
        }
    };

    const timeSince = (date) => {
        const now = new Date();
        const elapsed = now - new Date(date);
        const seconds = Math.floor(elapsed / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);
        const weeks = Math.floor(days / 7);

        if (weeks > 0) return `${weeks} week${weeks > 1 ? 's' : ''} ago`;
        if (days > 0) return `${days} day${days > 1 ? 's' : ''} ago`;
        if (hours > 0) return `${hours} hour${hours > 1 ? 's' : ''} ago`;
        if (minutes > 0) return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
        return `${seconds} second${seconds > 1 ? 's' : ''} ago`;
    };

    const addtocart = async (e) => {
        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';
        const common_id = localStorage.getItem('common_id') ? localStorage.getItem('common_id') : '';
        const id = e.target.id;
        if (selectedVariant != null) {
            try {
                const response = await fetch(`https://backend.amjgems.com/api/cart/add`, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ common_id: common_id, user_id: parseInt(user_id), product_code: product_code, quantity: parseInt(quantity), variant_id: selectedVariant.id }),
                });
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                const data = await response.json();
                if (data.full) {
                    alert("Quantity reached");
                }
                handleShowCart();
                if (data.cart.common_id) {
                    localStorage.setItem('common_id', data.cart.common_id);
                }
            } catch (error) {
                if (token || user_id) {
                    console.error('Error adding cart:', error);
                }
                else {
                    localStorage.clear();
                    cookies.remove('user_id');
                    handleShowLogin();

                }
            }
        }
        else {
            Swal.fire({
                title: 'Select Variant!',
            })
        }

    };


    // State management
    const [currentImageIndex, setCurrentImageIndex] = useState(0);

    // Existing code...

    const getAvailableImageCount = () => {
        if (!singleproduct?.product) return 0;

        let count = 0;
        for (let i = 1; i <= 10; i++) {
            if (singleproduct.product[`product_image_${i}`]) {
                count++;
            }
        }
        if (singleproduct.product?.product_video) {
            count++;
        }
        return count;
    };

    const handleThumbnailClick = (index) => {
        setCurrentImageIndex(index);
    };

    const processDescription = (html) => {
        const sanitizedHtml = DOMPurify.sanitize(html);
        const parser = new DOMParser();
        const doc = parser.parseFromString(sanitizedHtml, 'text/html');

        doc.querySelectorAll('img').forEach((img) => {
            img.classList.add('img-fluid');
        });

        return doc.body.innerHTML;
    };

    const productDescription = processDescription(singleproduct?.product?.description || '');

    const wishlistAdd = async () => {

        const token = localStorage.getItem('token');
        const user_id = localStorage.getItem('user_id') || '';
        try {
            const response = await fetch(`https://backend.amjgems.com/api/add-whishlist`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                body: JSON.stringify({ user_id: parseInt(user_id), product_code: product_code }),
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();

            const wishlistElement = document.getElementById('wishlist');

            if (data.success) {
                wishlistElement.style.color = 'red';  // Hide the wishlist
            }
            else if (data.remove) {
                wishlistElement.style.color = '#11111199';  // Show the wishlist
            }

        } catch (error) {
            if (token || user_id) {
                Swal.fire({
                    title: 'There seems to be an issue adding the item to the wishlist.',
                })
            }
            else {
                localStorage.clear();
                cookies.remove('user_id');
                handleShowLogin();
            }
        }
    }

    // Loading and error states
    if (loading) {
        return <Loader />;
    }
    if (error) {
        return <div>Error loading product: {error.message}</div>;
    }

    return (
        <div>
            <div className="product-container">
                <div id='sideimages'>
                    {Array.from({ length: getAvailableImageCount() - (singleproduct?.product?.product_video ? 1 : 0) }).map((_, index) => (
                        <>
                            {singleproduct?.product[`product_image_${index + 1}`] && (
                                <img
                                    key={index}
                                    className='img-thumbnail'
                                    onClick={() => handleThumbnailClick(index)}
                                    src={`https://backend.amjgems.com/${singleproduct?.product[`product_image_${index + 1}`]}`}
                                    alt={`Thumbnail ${index + 1}`}
                                />
                            )}
                        </>
                    ))}
                    {singleproduct?.product?.product_video && (
                        <video
                            className='img-thumbnail'
                            onClick={() => setCurrentImageIndex(getAvailableImageCount() - 1)}
                            src={`https://backend.amjgems.com/${singleproduct?.product?.product_video}`}
                        />
                    )}
                </div>
                <div className="product-image">
                    <button
                        className="heart-overlay"
                        style={{
                            color: singleproduct.whishlist ? 'red' : '#11111199',
                            backgroundColor: 'white',  // Example background color
                            border: '2px solid white',  // Example border
                            borderRadius: '11%',  // Optional: Make the button circular
                            padding: '3px'
                        }}
                        onClick={wishlistAdd}
                        id='wishlist'
                    >
                        <HeartFill size={20} /> {/* Use the filled heart icon */}
                    </button>
                    <button
                        className="share-overlay"
                        style={{
                            backgroundColor: 'white',  // Example background color
                            border: '2px solid white',  // Example border
                            borderRadius: '11%',  // Optional: Make the button circular
                            padding: '3px'
                        }}
                        onClick={() => {
                            setShowOptions(!showOptions);
                        }}
                        id='wishlist'
                    >
                        <Share size={20} /> {/* Use the filled heart icon */}
                    </button>
                    {showOptions && (
                        <div
                            style={{
                                position: "absolute",
                                top: "85px",
                                right: "3px",
                                backgroundColor: "#fff",
                                border: "1px solid #ccc",
                                borderRadius: "5px",
                                boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                                padding: "10px",
                                zIndex: 1000,
                            }}
                        >
                            <a
                                href={`https://wa.me/?text=${encodeURIComponent(
                                    `${`https://amjgems.com/productDetails.php?id=${singleproduct?.product?.id}`}`
                                )}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ display: "block", marginBottom: "10px" }}
                            >
                                <Whatsapp /> Share on WhatsApp
                            </a>
                            <a
                                href={`https://twitter.com/intent/tweet?url=${encodeURIComponent(`https://amjgems.com/productDetails.php?id=${singleproduct?.product?.id}`)}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ display: "block", marginBottom: "10px" }}
                            >
                                <Twitter /> Share on Twitter
                            </a>
                            <a
                                href="#"
                                onClick={() => {
                                    navigator.clipboard.writeText(
                                        `https://amjgems.com/productDetails.php?id=${singleproduct.product.id}`
                                    );
                                    Swal.fire({
                                        title: "Link copied to clipboard for Instagram!",
                                    })
                                }}
                                style={{ display: "block", marginBottom: "10px" }}
                            >
                                <Instagram /> Copy link for Instagram
                            </a>
                        </div>
                    )}

                    <Carousel activeIndex={currentImageIndex} onSelect={handleThumbnailClick} interval={null}>
                        {Array.from({ length: getAvailableImageCount() }).map((_, index) => (
                            <Carousel.Item key={index}>
                                {index < getAvailableImageCount() - (singleproduct?.product?.product_video ? 1 : 0)
                                    ?
                                    <>
                                        {singleproduct?.product[`product_image_${index + 1}`] && (
                                            <img
                                                className="d-block w-100"
                                                src={`https://backend.amjgems.com/${singleproduct?.product[`product_image_${index + 1}`]}`}
                                                alt={`Product Image ${index + 1}`}
                                            />

                                        )}
                                    </>
                                    :
                                    <>
                                        {singleproduct?.product?.product_video && (
                                            <video className="d-block w-100" autoPlay muted controls playsInline>
                                                <source src={`https://backend.amjgems.com/${singleproduct?.product?.product_video}`} type="video/mp4" />
                                            </video>
                                        )}
                                    </>

                                }
                            </Carousel.Item>
                        ))}
                    </Carousel>
                </div>
                <Helmet>
                    <title>{singleproduct?.product?.product_name} - Amjgems</title>
                    <meta name="description" content={singleproduct?.product?.product_name} />

                    {/* Open Graph Meta Tags */}
                    <meta property="og:title" content={singleproduct?.product?.product_name} />
                    <meta property="og:description" content={singleproduct?.product?.product_name} />
                    <meta property="og:image" content={`https://backend.amjgems.com/${singleproduct?.product?.product_image_1}`} />
                    {/* <meta property="og:url" content={link} /> */}
                    <meta property="og:type" content="product" />

                    <meta name="keywords" content={singleproduct?.product?.meta_tags} />
                    {/* Twitter Meta Tags */}
                    <meta name="twitter:card" content="summary_large_image" />
                    <meta name="twitter:title" content={singleproduct?.product?.product_name} />
                    <meta name="twitter:description" content={singleproduct?.product?.product_name} />
                    <meta name="twitter:image" content={`https://backend.amjgems.com/${singleproduct?.product?.product_image_1}`} />
                </Helmet>
                <div className="product-details">
                    <div className="product-title">{singleproduct?.product?.product_name}</div>

                    {/* Later in your render: */}
                    {showPrice !== null ? (
                        <div className="product-price">
                            <span id='discount'>Offer Price</span>
                            ${(showPrice * (1 - singleproduct?.product?.product_discount / 100)).toFixed(2)}
                            {singleproduct?.product?.product_discount && (<span className="price-cut">${showPrice}</span>)}
                        </div>
                    ) : (
                        <div>Loading price...</div>
                    )}

                    {singleproduct?.product?.product_discount && (
                        <div className="discount-tag">Flat {singleproduct.product.product_discount}% off</div>
                    )}

                    {singleproduct?.product?.single_product == 0 &&
                        (<div id='varientDropdown'>
                            <select
                                value={selectedVariant ? selectedVariant.id : ''}
                                onChange={(e) => {
                                    const selectedId = parseInt(e.target.value);
                                    const variant = singleproduct?.variant.find(v => v.id === selectedId);
                                    setSelectedVariant(variant);
                                    setQuantity(1);
                                    setShowPrice(variant.price);
                                }}
                            >
                                <option value="" disabled>Select a variant</option> {/* Default empty option */}
                                {singleproduct?.variant.map(variant => (
                                    <option key={variant.id} value={variant.id}>
                                        {variant.size} - {variant.pieces} pieces - [{variant.price} $]
                                    </option>
                                ))}
                            </select>
                        </div>)

                    }


                    {singleproduct?.product?.single_product > 0 && (singleproduct?.lowest_variant?.quantity == null || singleproduct?.lowest_variant?.quantity == 0) ? (
                        <p className="discount">Out of stock!</p>
                    ) : (
                        <>
                            {(singleproduct?.product?.single_product > 0 && singleproduct.lowest_variant.quantity <= 15) ? <p className="discount" id='leftquantity'>Only {singleproduct.lowest_variant.quantity} left!</p> : null}

                            {(selectedVariant !== null || singleproduct.product.single_product > 0) &&
                                (
                                    <div id='quantity'>
                                        <span>Quantity</span>
                                        <input type="number" value={quantity} readOnly />
                                        <button onClick={quantityHandler}>+</button>
                                        <button onClick={quantityReducer}>-</button>
                                    </div>
                                )
                            }
                            <div className="product-buttons">
                                <button className="product-button" onClick={addtocart}>ADD TO BAG</button>
                                <button id='buynow' onClick={addtocart} className="product-button">BUY NOW</button>
                            </div>
                        </>
                    )}
                </div>
            </div>

            <div id='descAndSpecifications'>
                <h5>Specification</h5>
                <div id="specification">
                    <div class="spec-column">
                        <ul>
                            {singleproduct.product.brand && <li><strong>Brand: </strong> {singleproduct.product.brand}</li>}
                            {singleproduct.product.certifications && <li><strong>Certifications: </strong> {singleproduct.product.certifications}</li>}
                            {singleproduct.product.clarity_grade && <li><strong>Clarity Grade: </strong> {singleproduct.product.clarity_grade}</li>}
                            {singleproduct.product.color && <li><strong>Color: </strong> {singleproduct.product.color}</li>}
                            {singleproduct.product.country_of_manufacture && <li><strong>Country of Manufacture: </strong> {singleproduct.product.country_of_manufacture}</li>}
                            {singleproduct.product.country_of_origin && <li><strong>Country of Origin: </strong> {singleproduct.product.country_of_origin}</li>}
                        </ul>
                    </div>
                    <div class="spec-column">
                        <ul>
                            {singleproduct.product.cut_grade && <li><strong>Cut Grade: </strong> {singleproduct.product.cut_grade}</li>}
                            {singleproduct.product.gemstone_creation && <li><strong>Gemstone Creation: </strong> {singleproduct.product.gemstone_creation}</li>}
                            {singleproduct.product.gemstone_type && <li><strong>Gemstone Type: </strong> {singleproduct.product.gemstone_type}</li>}
                            {singleproduct.product.item_depth && <li><strong>Item Depth: </strong> {singleproduct.product.item_depth}</li>}
                            {singleproduct.product.item_length && <li><strong>Item Length: </strong> {singleproduct.product.item_length}</li>}
                            {singleproduct.product.item_width && <li><strong>Item Width: </strong> {singleproduct.product.item_width}</li>}
                            {singleproduct.product.shape && <li><strong>Item Shape: </strong> {singleproduct.product.shape}</li>}
                            {singleproduct.product.cut && <li><strong>Item Cut: </strong> {singleproduct.product.cut}</li>}
                        </ul>
                    </div>
                </div>

                <h5>Policy</h5>
                <div id='policy'>
                    <h6><strong>Return Policy</strong></h6>
                    <div dangerouslySetInnerHTML={{ __html: singleproduct?.return_policy }} />

                    <h6><strong>Shipping Policy</strong></h6>
                    <div dangerouslySetInnerHTML={{ __html: singleproduct?.shipping_policy }} />

                    <h6><strong>Payment Policy</strong></h6>
                    <div dangerouslySetInnerHTML={{ __html: singleproduct?.payment_policy }} />
                </div>

                <h5>Description</h5>
                <div>
                    {showFullDescription
                        ? <>
                            <div id='discimg' dangerouslySetInnerHTML={{ __html: productDescription }} />

                            <div className="toggle-description" onClick={() => setShowFullDescription(!showFullDescription)}>
                                Show Less
                            </div>
                        </>
                        : <>
                            <div id='discimg' dangerouslySetInnerHTML={{ __html: singleproduct?.product?.description.substring(0, 100) }} />
                            <div className="toggle-description" onClick={() => setShowFullDescription(!showFullDescription)}>
                                Show More
                            </div>
                        </> // Show first 100 characters
                    }
                </div>

            </div>
            <div id='review'>
                <button onClick={handleShowReview} className='reviewbut'>Write a Review</button>
                {reviews.map((review) => (
                    <div className="review-container" key={review.id}>
                        <span className="author-name">{review.user_name}</span>
                        <div className="review-content">{review.review}</div>
                        <div className="author">
                            <div className="rating">
                                {[...Array(5)].map((_, index) => (
                                    <span key={index} className={index < review.rating ? "star" : "nostar"}>★</span>
                                ))}
                            </div>
                            <span className="author-date">{timeSince(review.created_at)}</span>
                            <div id='reviewimages'>
                                {review?.review_image_1 && <img src={`https://backend.amjgems.com/${review?.review_image_1}`} alt="" />}
                                {review?.review_image_2 && <img src={`https://backend.amjgems.com/${review?.review_image_2}`} alt="" />}

                            </div>
                        </div>
                    </div>
                ))}
                <Link to={`/reviewshow/${product_code}`} id='showmorebut'>
                    <button className="product-button">Show All Reviews</button>
                </Link>
            </div>
            {/* Section Title  */}
            <div class="may-you-also-like-section-title">
                <h2 class="may-you-also-like-heading">May You Also Like</h2>
            </div>

            {/* Product Showcase */}
            <div class="may-you-also-like-products">
                {singleproduct?.liked_products?.data && singleproduct.liked_products.data.map((product) => (
                    <div class="may-you-also-like-card">
                        <img src={`https://backend.amjgems.com/${product.product_image_1}`} alt="Bicycle Helmet" class="may-you-also-like-image" />
                        <h3 class="may-you-also-like-title">{product.product_name}</h3>
                        <div class="may-you-also-like-rating">★★★★★</div>

                        <p class="price">${(product?.variant?.price * (1 - product.product_discount / 100)).toFixed(2)}{product?.product_discount && (<span class="price-cut">${product?.variant?.price}</span>)}</p>
                        {product?.variant?.quantity === 0 && product?.single_product > 0 ?
                            (<p class="discount">Out of stock!</p>) :
                            (<>{product?.variant?.quantity < 15 && product?.single_product > 0 && <p class="discount">Only {product?.variant?.quantity} left!</p>}</>)
                        }
                        <Link to={`/productshow/${product.product_code}`}><button class="may-you-also-like-btn">DISCOVER MORE</button></Link>
                    </div>
                ))}

            </div>
            <AddReviewModal show={reviewShow} onClose={handleCloseAddReview} product_id={product_code} />
            <LoginModal show={loginShow} onClose={handleClose} />
            <CartModal show={showCart} onClose={handleClose} />
        </div>
    );
}

export default Productshow;
